import React, { useEffect, useRef,useState } from 'react';
import CodeMirror from "codemirror"
import "codemirror/lib/codemirror.css"
import "codemirror/mode/javascript/javascript"
import js2jsx from "js2jsx"
import * as defaultString from "./data"

var myCodeMirror,myCodeMirrorR;
function App() {
  const left = useRef();
  const right = useRef();
  const [state,setState] = useState({
    type:0,
    isDefault:true,
    ready:false
  });
  useEffect(()=>{
    myCodeMirror = CodeMirror(left.current, {
      value: "function myScript(){return 100;}\n",
      mode:  "javascript",
      lineWrapping:true,
      lineNumbers:true
    });
    myCodeMirrorR = CodeMirror(right.current, {
      value: "function myScript(){return 100;}\n",
      mode:  "javascript",
      lineWrapping:true,
      lineNumbers:true
    });
    myCodeMirror.on()
    myCodeMirror.setValue(defaultString.react)
    myCodeMirrorR.setValue(js2jsx.js2jsxByReact(defaultString.react))

    myCodeMirror.on("keypress",(e)=>{
      if(state.isDefault){
        if(state.type == 0){
          myCodeMirror.setValue(defaultString.react)
        }else{
          myCodeMirror.setValue(defaultString.vue)
        }
      }
      if(state.type == 0){
        myCodeMirrorR.setValue(js2jsx.js2jsxByReact(myCodeMirror.getValue()))
      }else{
        myCodeMirrorR.setValue(js2jsx.js2jsxByVue(myCodeMirror.getValue()))
      }
    })
    setState({...state,ready:true})
  },[])
  useEffect(()=>{
    if(!myCodeMirror || !myCodeMirrorR || !state.ready)return 
    if(state.isDefault){
      if(state.type == 0){
        myCodeMirror&&myCodeMirror.setValue(defaultString.react)
      }else{
        myCodeMirror&&myCodeMirror.setValue(defaultString.vue)
      }
    }
    if(state.type == 0){
      myCodeMirrorR&&myCodeMirrorR.setValue(js2jsx.js2jsxByReact(myCodeMirror.getValue()))
    }else{
      myCodeMirrorR&&myCodeMirrorR.setValue(js2jsx.js2jsxByVue(myCodeMirror.getValue()))
    }
  },[state.type,state.ready])
  return (
    <div style={{display:"flex",height:"100vh"}}>
      
      <div ref={left} style={{width:"50%",position:"relative"}}>
      <div style={{position:"absolute",right:0,zIndex:9999,background:"#fff"}}>
        <label>选择模式 </label>
        <select onChange={(e)=>{
          setState({...state,type:e.target.value});
        }} defaultValue={0}>
          <option value="0" >react</option>
          <option value="1" >vue</option>
        </select>
      </div>
      </div>
      <div ref={right} style={{width:"50%"}}></div>
    </div>
  );
}

export default App;
